import dayjs from 'dayjs';
import 'dayjs/locale/es';

// ---------------------------------------------------------------------------
// Tipos de validadores para contraseña
// ---------------------------------------------------------------------------
export const VALIDADOR_TIPO_ERROR = 'error';
export const VALIDADOR_TIPO_AVISO = 'warning';
export const VALIDADOR_TIPO_OK = 'ok';

export const VALIDADOR_VAL_NULL = 0;
export const VALIDADOR_VALOR_ERROR = 10;
export const VALIDADOR_VALOR_AVISO = 40;
export const VALIDADOR_VALOR_BUENO = 70;
export const VALIDADOR_VALOR_EXCELENTE = 100;

export const MIN_LEN_PASSWORD = 8;
export const EXCELENTE_LEN_PASSWORD = 16;

export const PERCENT_SIMILARITY_PASSWORD = 0.7;

export const MAX_LEN_NAMES = 100;
export const MAX_LEN_DOC = 128;
export const MAX_LEN_TEXT_AREA = 2048;
export const EXACT_LEN_ERROR = value =>
  `Asegúrese de que este campo tenga ${value} caracteres.`;
export const getMensajeErrorLargoMaximo = value =>
  `Asegúrese de que este campo no tenga más de ${value} caracteres.`;
export const TOTP_CODE_ERROR =
  'El código de verificación solo contiene números.';
export const RECOVERY_CODE_ERROR =
  'El código de verificación solo contiene números y letras.';
export const UNREGISTERED_DOMAIN_ERROR = 'Dominio no registrado.';

export const ONLY_NUMBERS_VALIDATOR_MSG =
  'Muy fácilmente adivinable! No uses solo números';
export const DATO_REQUERIDO = 'Este es un dato requerido';
export const REQUERIDO_VALIDADOR_MENSAJE = `Mínimo ${MIN_LEN_PASSWORD} caracteres de longitud`;
export const getMensajeErrorLargoMinimo = value =>
  `Muy corta! Agrega al menos ${MIN_LEN_PASSWORD - value.length} caracteres`;
export const PASSWORD_SIMILARITY_MSG =
  'No uses información personal en la contraseña!';
export const CONTRASENAS_DEBEN_SER_IGUALES =
  'Las contraseñas deben ser iguales';
export const CONTRASENA_INVALIDA = 'Contraseña inválida';

export const VALIDADOR_AVISO =
  'Venís bien! Agregá números, mayúsculas o más caracteres para que sea más difícil de adivinar.';
export const VALIDADOR_BUENO = 'Ahora si! Tu contraseña es segura';
export const VALIDADOR_EXCELENTE = 'Wow! Tu contraseña es muy segura!';

export const REQUERIDO = 'Requerido';
export const CORREOS_DEBEN_SER_IGUALES = 'Los correos deben ser iguales';
export const CORREO_INVALIDO = 'Ingrese un email válido';
export const NOMBRE_INVALIDO = 'No uses símbolos ni números';
export const DESCRIPTION_NO_RELATED_QUESTIONS =
  'No existen preguntas relacionadas.';

export const ALERT_DOMAINS_TEXT =
  'El email que acaba de ingresar no es institucional o su institución no está registrada dentro de Usuario gub.uy. Por favor solicite el registro de su institución a identificacion.electronica@agesic.gub.uy indicando su nombre, apellido, cédula de identidad y correo institucional.';

// ---------------------------------------------------------------------------
// Cedula de Identidad Uruguaya
// ---------------------------------------------------------------------------
export const DOCUMENTO_INVALIDO = 'El documento no es válido.';
export const FORMATO_CI_INVALIDO =
  'Ingrese su documento sin puntos ni guiones.';
export const UY_CODE = 'uy';
export const CI_CODE = '68909';

// ---------------------------------------------------------------------------
// Codigo de Argentina
// ---------------------------------------------------------------------------
export const AR_CODE = 'ar';

// ---------------------------------------------------------------------------
// Timezone
// ---------------------------------------------------------------------------

export const TIMEZONE_MONTEVIDEO = 'America/Montevideo';

// ---------------------------------------------------------------------------
// Tipos de mensajes para los tooltips
// ---------------------------------------------------------------------------
export const INFO_MENSAJE =
  'Ingresalo exactamente como está escrito en tu documento de identidad';
export const DOCUMENTO_MENSAJE = 'Ingresalo sin puntos ni guiones';

// ---------------------------------------------------------------------------
// Landing
// ---------------------------------------------------------------------------
export const LANDING_HERO_TEXT = 'Elegí la forma de identificarte digitalmente';
export const LANDING_HERO_TEXT_DESC =
  'Usuario gub.uy es una solución digital que pone a disposición el Estado uruguayo para que las personas se identifiquen digitalmente y accedan a los servicios digitales de los organismos públicos o empresas privadas, evitando traslados y costos innecesarios. Cumple dos roles: por un lado, actúa como un punto de integración de distintos métodos de identificación digital y por otro, brinda una opción de identificación a través de un registro propio.';
export const LANDING_SMALL_SECTION_1_TITLE = 'Una sola cuenta para todo';
export const LANDING_SMALL_SECTION_1_DESC =
  'Con tu Usuario gub.uy podés ingresar de manera cómoda, fácil y segura a muchos servicios digitales del Estado uruguayo.';
export const LANDING_SMALL_SECTION_2_TITLE =
  'Hacé trámites desde donde quieras';
export const LANDING_SMALL_SECTION_2_DESC =
  'Elegí el método para identificarte digitalmente. Podrás usar más de 190 servicios digitales y realizar 2.300 trámites en línea integrados a esta solución digital.';
export const LANDING_SMALL_SECTION_3_TITLE = 'Múltiples niveles de seguridad';
export const LANDING_SMALL_SECTION_3_DESC =
  'Hay distintas maneras de verificar una identidad en el mundo digital. Algunos de los trámites y servicios que las personas podemos realizar de manera remota, requieren de  garantías  sobre  quién  está  del  otro  lado  de  ese intercambio.';
export const LANDING_SMALL_SECTION_3_SUBTITLE_1 =
  'Nivel de seguridad Básico - Auto-registrado';
export const LANDING_SMALL_SECTION_3_SUBDESC_1 =
  'Es  el  nivel  de  confianza  básico  en  el  que  te  registrás  completando  un formulario en la web con confirmación a través de un correo electrónico. Se ingresa con usuario y contraseña. Permite iniciar y dar seguimiento a trámites y servicios, públicos y privados. Podés elevar la seguridad de tu identidad a nivel Intermedio (verificado) y acceder a los trámites y servicios que requieren este nivel.';
export const LANDING_SMALL_SECTION_3_SUBTITLE_2 =
  'Nivel de seguridad Intermedio - Verificado';
export const LANDING_SMALL_SECTION_3_SUBTITLE_3 =
  'Nivel de seguridad Avanzado - Equivalente a la presencialidad';
export const LANDING_SMALL_SECTION_4_TITLE = 'Verificación en dos pasos';
export const LANDING_SMALL_SECTION_4_DESC =
  'La autenticación en dos pasos, como lo indica su nombre, trata de tener dos barreras de protección para acceder a una cuenta. La primera barrera es el tradicional password, conocido y definido (algo que sé) por el usuario. Y  la  segunda  por  lo  general  es  un  código,  obtenido  de  forma  aleatoria  y regenerado  en  períodos  cortos  de  tiempo.  De  esta  forma  por  más  que  se descubra la clave será más difícil tener acceso al código y por consiguiente a la cuenta. Esto es conocido como autenticación de dos factores.';
export const LANDING_SMALL_SECTION_4_DESC_2 =
  'Con  tu  Usuario  gub.uy  podrás  autenticarte  de  forma  más  segura  en  los servicios asociados y navegar de forma más segura y sin preocupaciones.';
export const LANDING_SMALL_SECTION_4_TITLE_2 = '¿Cómo funciona?';
export const LANDING_SMALL_SECTION_4_DESC_3 =
  'Cada vez que intentes iniciar sesión te pediremos la contraseña y, a continuación, el código de inicio de sesión de tu app de autenticación.';

export const LANDING_FOOTER_MSG = 'Empezá a usar tu Usuario gub.uy';
export const LANDING_FOOTER_BUTTON = 'Registrate';
export const LANDING_BOTON_PREGUNTAS_FRECUENTES = 'Preguntas frecuentes';
export const LANDING_BOTON_REGISTRATE = 'Registrate';
export const LANDING_BOTON_INGRESA = 'Ingresá';
export const LANDING_BOTON_CONOCE = 'Conocé más sobre gub.uy';

export const LANDING_BENEFICIOS = 'Beneficios:';
export const LANDING_MANERA_COMODA =
  'Una manera cómoda y segura de identificarte.';
export const LANDING_INGRESA_DE_MANERA =
  'Ingresá de manera fácil y segura a todos los servicios digitales que estén integrados. Actualmente podés acceder a más de 2.300 trámites en línea del Estado uruguayo.';
export const LANDING_AHORRAS_TIEMPO = 'Ahorrás tiempo.';
export const LANDING_PODES_REALIZAR =
  'Podés realizar muchos trámites desde tu computadora o celular, sin necesidad de dirigirte presencialmente a los organismos.';
export const LANDING_INFORMACION_PROTEGIDA = 'Tu información está protegida.';
export const LANDING_SISTEMA_HERRAMIENTA =
  'El sistema/herramienta con las políticas de seguridad y privacidad digital más actualizadas.';
export const LANDING_CON_ESTE_NIVEL =
  'Con este nivel se incrementa la seguridad de tu Identidad Digital ya que verifica que la persona es quien dice ser. Existen dos formas para elevar el nivel de seguridad de Básico (Autorregistrado) a Intermedio (Verificado):';
export const LANDING_MEDIANTE_FIRMA_DIGITAL = 'Mediante firma digital';
export const LANDING_PRESENCIALMENTE_EN_PUNTOS_AUTORIZADOS =
  'Presencialmente en los puntos autorizados';
export const LANDING_NECESITAS_CEDULA =
  'Necesitás cédula de identidad con chip, un lector de cédula y conocer el PIN.';
export const LANDING_ID_DIGITAL_ABITAB = 'Id Digital de Abitab';
export const LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ABITAB =
  'Necesitás contar con una Identidad Digital de Abitab certificada presencialmente en un';
export const LANDING_LOCAL_ABITAB = 'local de Abitab';
export const LANDING_DESCARGAR_CELULAR =
  'y descargar en tu celular una aplicación para identificarte. Este método permite firmar digitalmente.';
export const LANDING_ID_ANTEL = 'TuID de Antel';
export const LANDING_NECESITAS_CONTAR_CON_IDENTIDAD_ANTEL =
  'Necesitás contar con una Identidad Digital de Antel certificada presencialmente en un';
export const LANDING_LOCAL_ANTEL = 'local de Antel';
export const LANDING_MAS_INFORMACION = 'Más información';
export const LANDING_EMPEZA_A_USAR_USUARIO_GUB = 'Empezá a usar Usuario gub.uy';

// ---------------------------------------------------------------------------
// Links externos
// ---------------------------------------------------------------------------

export const FIRMA_DIGITAL =
  'https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/firma-digital/que-es-firma-digital';
export const PUNTOS_ATENCION_CIUDADANIA =
  'https://www.gub.uy/politicas-y-gestion/puntos-atencion-ciudadania-departamento';
export const MAS_INFO =
  'https://www.gub.uy/agencia-gobierno-electronico-sociedad-informacion-conocimiento/comunicacion/publicaciones/autenticacion-dos-pasos';
export const LOCALES_ABITAB =
  'https://www.abitab.com.uy/innovaportal/v/11702/11/abitab/locales';
export const LOCALES_ANTEL = 'https://www.tuid.uy/user/regLocals';

// ---------------------------------------------------------------------------
// Mis Datos - Modificacion de correo
// ---------------------------------------------------------------------------
export const REVERIFICAR_CORREO_LINK_TEXT = 'Reenviar correo »';
export const REVERIFICAR_CORREO_TEXT = correo =>
  `Todavía no verificaste tu nueva dirección (${correo}).`;
export const ACTIVAR_CUENTA_LINK_TEXT = 'Reenviar correo de activación »';
export const ACTIVAR_CUENTA_TEXT = 'Todavía no activaste tu cuenta.';
export const ULTIMO_PASO_TEXT = 'Un último paso...';
export const VALIDAR_CORREO_TEXT = 'Validar correo';
export const VALIDA_CAMBIO_TEXT =
  'Validá el cambio de tu correo electrónico de tu Usuario gub.uy por ';
export const HACIENDO_CLICK_TEXT = ' haciendo click en el botón debajo:';
export const YA_CASI_TEXT = 'Ya casi terminás...';
export const PARA_CONTINUAR_TEXT =
  'Para continuar, revisá el correo electrónico que enviamos a tu casilla.';

// ---------------------------------------------------------------------------
// Tipos de links de ayuda
// ---------------------------------------------------------------------------
export const INFO_LINK_TEXT = '¿Qué es esto?';

// ---------------------------------------------------------------------------
// Seguridad
// ---------------------------------------------------------------------------
export const CARD_SEGURIDAD_TITLE = 'Administrar tu Usuario gub.uy';

export const INFO_SEC_CONTRASEÑA_TITLE = 'Contraseña';
export const INFO_SEC_CONTRASEÑA_CONTEXT_TEXT = 'Última modificación: ';
export const INFO_SEC_CONTRASEÑA_BUTTON = 'Modificar contraseña';
export const INFO_SEC_CONTRASEÑA_ARIA_LABEL = 'Modificar contraseña';

export const INFO_SEC_EMAIL_TITLE = 'Correo electrónico';
export const INFO_SEC_EMAIL_CONTEXT_TEXT = 'Dirección:';
export const INFO_SEC_EMAIL_BUTTON = 'Modificar';
export const INFO_SEC_EMAIL_ARIA_LABEL = 'Modificar correo electrónico';

export const INFO_SEC_TELEFONO_TITLE = 'Teléfono';
export const INFO_SEC_TELEFONO_CONTEXT_TEXT = 'Número:';
export const INFO_SEC_TELEFONO_BUTTON = 'Modificar';
export const INFO_SEC_TELEFONO_BUTTON_AGREGAR = 'Agregar';
export const INFO_SEC_TELEFONO_ARIA_LABEL = 'Modificar número de teléfono';
export const INFO_SEC_TELEFONO_SIN_REGISTRO = 'No ingresado';

export const INFO_SEC_NIVEL_ID_TITLE = 'Nivel de identificación digital';

export const INFO_SEC_NIVEL_REGISTRO_CONTEXT_TEXT = 'Nivel de Registro:';
export const INFO_SEC_NIVEL_REGISTRO_PREGUNTA = '¿Cómo puedo mejorarlo?';
export const INFO_SEC_NIVEL_REGISTRO_BUTTON = 'Aumentar Nivel de Registro';

export const INFO_SEC_NIVEL_AUTH_CONTEXT_TEXT = 'Nivel de Autenticación:';
export const INFO_SEC_NIVEL_AUTH_PREGUNTA = '¿Qué es esto?';
export const INFO_SEC_NIVEL_AUTH_BUTTON = 'Aumentar Nivel de Autenticación';

export const INFO_SEC_ACTIVIDAD_TITLE = 'Actividad';
export const INFO_SEC_ACTIVIDAD_CONTEXT_TEXT = 'Último ingreso: ';

export const INFO_SEC_GARANTIA_TITLE = 'Firma digital';

export const INFO_SEC_APLICACIONES_TITLE = 'Aplicaciones';
export const INFO_SEC_APLICACIONES_BUTTON = 'Retirar acceso';
export const INFO_SEC_APLICACIONES_ARIA_LABEL =
  'Retirar acceso a la aplicación';

export const INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_ASOCIADO = 'Asociado';
export const INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_MODIFICAR =
  'Modificar Firma Digital';
export const INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_REGISTRAR =
  'Utilizar Firma Digital';
export const INFO_SEC_NIVEL_SEGURIDAD_FIRMA_DIGITAL_NIVEL = 'Nivel Actual:';
export const INFO_SEC_NIVEL_SEGURIDAD_CONCURRIR_PAC =
  'Concurrir a un Punto de Atención Ciudadana';
export const INFO_SEC_NIVEL_SEGURIDAD_AGENDAR_VIDEOLLAMADA =
  'Agendar Videollamada';
export const INFO_SEC_NIVEL_SEGURIDAD_TITLE = 'Nivel de seguridad';
export const INFO_SEC_NIVEL_SEGURIDAD_LINK_FIRMADO = 'Link a documento firmado';
export const INFO_SEC_NIVEL_SEGURIDAD_CERTIFICADO =
  'Certificado de Firma digital:';

export const SECTION_IDENTIDAD_TITLE = 'Garantía de identidad';

export const INFO_SEC_MFA_TITLE = 'Verificación en dos pasos';
export const INFO_SEC_MFA_DESACTIVADA = 'Desactivada';
export const INFO_SEC_MFA_ACTIVADA = 'Activada';
export const INFO_SEC_MFA_DISABLED_BUTTON = 'Configurar';
export const INFO_SEC_MFA_DISABLED_ARIA_LABEL =
  'Activar verificación en dos pasos';
export const INFO_SEC_MFA_STATE_TEXT = 'Estado: ';
export const INFO_SEC_MFA_EDIT_BUTTON = 'Desactivar verificación en dos pasos';

export const INFO_ICONO_NIVEL_SEGURIDAD = 'Nivel de seguridad';
export const INFO_ICONO_MAS_INFORMACION = 'Más información';

export const WARNING_NO_PASSWORD_METHOD_SEGURIDAD =
  'Iniciaste sesión con un Proveedor de Identidad y aparentemente no estás registrado. Por este motivo no tienes acceso a ciertas funcionalidades. Si lo deseas puedes registrarte.';
export const WARNING_RID_0_SEGURIDAD =
  'Iniciaste sesión con un Proveedor de Identidad y aparentemente no validaste tu correo electrónico. Por este motivo no tienes acceso a ciertas funcionalidades. Si lo deseas puedes reenviar el correo para validarlo.';
export const WARNING_CUENTA_BLOQUEADA_SEGURIDAD =
  'Iniciaste sesión con un Proveedor de Identidad y aparentemente ya había una cuenta asociada con tu documento. Por este motivo tu cuenta ha sido bloqueada y no tienes acceso a ciertas funcionalidades. Si lo deseas puedes reestablecer tu contraseña para desbloquearla.';
export const WARNING_CUENTA_BLOQUEADA_SEGURIDAD_LINK =
  'Reestablece tu contraseña.';

export const WARNING_NO_PASSWORD_METHOD_SEGURIDAD_LINK = 'Registrarse »';

export const TEXTO_INFORMATIVO_NIVEL_BASICO =
  'Permite iniciar y seguir algunos servicios del Estado.';

export const TEXTO_INFORMATIVO_NIVEL_INTERMEDIO =
  'Permite realizar la mayoría de los servicios digitales del Estado.';

export const TEXTO_INFO_VALIDAR_IDENTIDAD =
  'Recomendamos validar tu identidad y avanzar al nivel intermedio para acceder a más servicios.';

export const TEXTO_VIRTUAL = 'Virtual';
export const TEXTO_PRESENCIAL = 'Presencial';

export const TEXTO_OBTENER_NIVEL_INTERMEDIO = 'Obtener nivel intermedio:';

export const TEXTO_INFO_ACTIVAR_SEGUNDO_FACTOR =
  'Recomendamos activar la verificación en dos pasos para darle más seguridad a tu cuenta.';

export const TEXTO_VER_DOCUMENTO_FIRMADO = 'Ver documento firmado';

export const TEXTO_CONOCE_MAS = 'Conoce más sobre ';
export const TEXTO_LINK_CONOCE_MAS =
  'los niveles de seguridad y el nivel avanzado.';

export const TEXTO_ELEVADO_POR_FIRMA = ' - Firma Digital';
export const TEXTO_ELEVADO_POR_VIDEOLLAMADA = ' - Trámite Videollamada';
export const TEXTO_ELEVADO_POR_ELEVACION = ' - Elevación por Agente';

export const TEXTO_ELIGE_METODO =
  'Elige un método para obtener el nivel Intermedio:';

export const TEXTO_SE_RETIRO_ACCESO =
  'Se retiró el acceso de la aplicación correctamente.';
export const TEXTO_HUBO_ERROR_RETIRAR_ACCESO =
  'Hubo un error al retirar el acceso de la aplicación.';

export const TEXTO_WARNING_TRAMITE_RECHAZADO = (fecha, hora) =>
  `Su identidad no se pudo validar durante la videollamada llevada a cabo el día ${fecha} a las ${hora}. Por más información acerca de tus niveles de seguridad en tu identificación digital, puedes ingresar al siguiente`;

export const TEXTO_ERROR_INICIAR_PROCESO_FIRMA =
  'Ha ocurrido un error con la plataforma de Firma Digital. Por favor, inténtelo más tarde.';

export const TEXTO_TITULO_MODAL_INGRESO_VIDEOLLAMADA =
  'Ingresar a la videollamada';
export const TEXTO_MODAL_INGRESO_VIDEOLLAMADA = (fecha, hora) =>
  `La videollamada está agendada para el día ${fecha} a las ${hora}. Podrás ingresar cuando falten 5 minutos.`;
export const TEXTO_RID_0 = 'Muy bajo';
// ---------------------------------------------------------------------------
// BROKER - OIDC
// ---------------------------------------------------------------------------
export const TITULO_LOGIN_BROKER =
  'Elige un método para identificarte en ID Uruguay';
export const TITULO_LOGIN_CEDULA =
  'Identificate en ID Uruguay utilizando tu cédula de Identidad con Chip';

export const SCOPES = ['personal_info', 'email', 'document', 'profile'];
export const PLURAL_SCOPES = ['personal_info'];

export const MFA_TITLE = 'Identificación digital';
export const MFA_TITLE_CI = 'Cédula de Identidad con Chip';

export const IDP_CHALLENGE = 'idp';

export const USERNAME_CHALLENGE = 'username';
export const USERNAME_FIELD_NAME = 'username';
export const USERNAME_FIELD_TEXT = 'Número de documento';
export const USERNAME_FIELD_LABEL = 'Número de cédula uruguaya';
export const USERNAME_FIELD_DESCRIPTION = 'Ingresá tu cédula de identidad';
export const USERNAME_FIELD_PLACEHOLDER = 'Ej. 16180339';
export const USERNAME_FIELD_LINK_TEXT = 'No tengo documento uruguayo';

export const DOCNUM_FIELD_NAME = 'numero_documento';

export const EMAIL_FIELD_NAME = 'email';
export const EMAIL_FIELD_LABEL = 'Correo electrónico';

export const CAPTCHA_FIELD_NAME = 'captchaResponse';

export const PASSWORD_CHALLENGE = 'urn:iduruguay:am:password';
export const PASSWORD_FIELD_NAME = 'password';
export const PASSWORD_FIELD_LABEL = 'Ingresa tu contraseña';
export const PASSWORD_FIELD_DESCRIPTION = 'Ingresá tu contraseña';
export const PASSWORD_FIELD_LINK_TEXT = 'Olvidé mi contraseña';
export const PASSWORD_MOSTRAR_CONSTRASEÑA = 'Mostrar contraseña';

export const FOREIGN_USERNAME_CHALLENGE = 'foreignUsername';
export const FOREIGN_USERNAME_FIELD_DESCRIPTION =
  'Ingresá la información de tu documento.';

export const COUNTRY_FIELD_NAME = 'pais_emisor';
export const COUNTRY_FIELD_TEXT = 'País emisor';
export const COUNTRY_FIELD_ARIA_LABEL = 'País emisor del documento';

export const DOCTYPE_FIELD_NAME = 'tipo_documento';
export const DOCTYPE_FIELD_TEXT = 'Documento de Identidad';
export const DOCTYPE_FIELD_ARIA_LABEL = 'Documento de Identidad';

export const CI_CHALLENGE = 'ci';
export const CI_FIELD_DESCRIPTION =
  'Está ingresando con proveedor de identidad Cédula de Identidad Digital. Para continuar, es necesario instalar un plug-in verificado.';
export const CI_CHALLENGE_BUTTON_TEXT = 'Continuar';

export const TOTP_CHALLENGE = 'urn:iduruguay:am:totp';
export const TOTP_FIELD_NAME = 'totp';
export const VERIFICATION_CODE_FIELD_LABEL = 'Código de verificación';
export const TOTP_FIELD_DESCRIPTION =
  'Ingresá el código de tu app de autenticación.';
export const TOTP_FIELD_PLACEHOLDER = 'Ej. 123456';
export const RECOVERY_CODE_CHALLENGE = 'urn:iduruguay:am:recoverycode';
export const RECOVERY_CODE_FIELD_NAME = 'recoverycode';
export const RECOVERY_CODE_FIELD_TITLE = 'Revisá tu casilla de correo';
export const RECOVERY_CODE_FIELD_ENFORCE_TITLE =
  'Confirma tu identidad para continuar';
export const RECOVERY_CODE_FIELD_PLACEHOLDER = 'Ej. A12B3456';
export const RECOVERY_CODE_FIELD_DESCRIPTION =
  'Te enviamos el código de verificación a tu correo ';
export const RECOVERY_CODE_FIELD_ENFORCE_DESCRIPTION =
  'Para reforzar la seguridad de tu cuenta en usuario gub.uy, te enviamos el código de verificación al correo ';

export const AE3_CHALLENGE = 'ae3';
export const AE3_CHALLENGE_DESCRIPTION =
  'Para iniciar sesión elige uno de los métodos que están a continuación:';

export const ADD_INST_EMAIL_CHALLENGE = 'institutional_email';
export const ADD_INST_EMAIL_FIELD_NAME = 'institutional_email';
export const ADD_INST_EMAIL_FIELD_TITLE = 'Ingrese su email institucional';
export const ADD_INST_EMAIL_FIELD_DESCRIPTION =
  'Para ingresar a la consola de Agentes es necesario contar con un email institucional. Para continuar, agregue el suyo';
export const ADD_INST_EMAIL_FIELD_LABEL = 'Email institucional';

export const NEXT_CHALLENGE_BUTTON_TEXT = 'Continuar';
export const PREVIOUS_CHALLENGE_BUTTON_TEXT = 'Cancelar';
export const CANCEL_BUTTON_TEXT = 'Cancelar';
export const CANCEL_AND_PREVIOUS_BUTTON_TEXT = 'Cancelar y volver';
export const BACK_SEGURIDAD_BUTTON_TEXT = 'Volver a Seguridad';

export const IDP_FIELD_DESCRIPTION = 'Otros proveedores de identidad';

export const MORE_RID_REQUIRED = 'more_rid';
export const MORE_AE_REQUIRED = 'more_ae';
export const MORE_RID_AND_AE_REQUIRED = 'more_rid_and_ae';
export const MORE_NID_REQUIRED_TITLE =
  'Necesitás más nivel de identificación digital (NID)';
export const MORE_NID_REQUIRED_DESCRIPTION =
  'Te recomendamos las siguientes alternativas:';
export const MORE_NID_REQUIRED_OPTION1 = 'Iniciá sesión de otra manera';
export const MORE_NID_REQUIRED_OPTION2 = 'Activá la autenticación en dos pasos';
export const MORE_NID_REQUIRED_OPTION3 = 'Validá tu identidad digital';
export const MORE_NID_REQUIRED_LINK = '¿Por qué ocurre esto?';

// ---------------------------------------------------------------------------
// MFA
// ---------------------------------------------------------------------------
export const WITH_USER_METHOD = 'user';
export const WITH_CI_METHOD = 'ci';
export const ERROR_CODE_NO_ACTIVATED_ACCOUNT = 'account_no_activated';
export const IDP_ARIA_LABEL = 'Iniciar sesión con ';
export const MFA_CARD_TITLE = 'Autenticación en dos pasos';
export const MFA_CARD_NO_ACTIVATE_DESC =
  ' Agrega seguridad adicional a tu cuenta al iniciar sesión';
export const MFA_SECTION_DEFAULT_TEXT = '¿Cómo funciona?';
export const MFA_SECTION_DEFAULT_DESC =
  'Cada vez que intentes iniciar sesión te pediremos la contraseña y, a continuación, el código de inicio de sesión de tu app de autenticación.';
export const MFA_BACK_BUTTON_TEXT = 'Volver';
export const MFA_BACK_BUTTON_ARIA_LABEL = 'Cancelar';
export const MFA_CONF_BUTTON_TEXT = 'Configurar';
export const MFA_CONF_BUTTON_ARIA_LABEL =
  'Configurar autenticación en dos pasos';
export const MFA_TOTP_CARD_TITLE_TEXT = 'Configurá tu app de autenticación';
export const MFA_TOTP_CARD_DESC_TEXT =
  'Descargá una aplicación de autenticación (como Google Authenticator). En la aplicación debes crearte una nueva cuenta y escanear este código QR.';
export const MFA_TOTP_CARD_QR_DESC_TEXT =
  'O ingresá este código en la aplicación de autenticación';
export const MFA_TOTP_CARD_SUBMIT_BUTTON_TEXT = 'Continuar';
export const MFA_TOTP_CARD_BACK_BUTTON_TEXT = 'Cancelar';
export const MFA_TOTP_VERIFY_TITLE = 'Configurá tu app de autenticación';
export const MFA_TOTP_VERIFY_DESC =
  'Ingresá el código de 6 dígitos que visualizás en tu app de autenticación.';
export const MFA_TOTP_VERIFY_SUBMIT_BUTTON_TEXT = 'Verificar';
export const MFA_TOTP_VERIFIED_TITLE = 'Configuración exitosa';
export const MFA_TOTP_VERIFIED_DESC =
  'A partir de ahora usarás tu app de autenticación para iniciar sesión con tu Usuario gub.uy.';
export const MFA_TOTP_MODAL_DELETE_TITLE =
  '¿Desactivar autenticación en dos pasos?';
export const MFA_TOTP_MODAL_DELETE_DESC =
  'Desactivando la autenticación en dos pasos eliminás la capa adicional de seguridad y sólamente te pediremos la contraseña para iniciar sesión.';
export const MFA_TOTP_CODE_LENGTH = 6;
export const MFA_RECOVERY_CODE_LENGTH = 8;
export const MFA_TOTP_DEACTIVATE_BUTTON_TEXT = 'Desactivar';
export const MFA_TOTP_DEACTIVATE_ARIA_LABEL = 'Desactivar TOTP';
export const MFA_TOTP_DEACTIVATE_CANCEL_ARIA_LABEL =
  'Calcelar desactivación de TOTP';
export const MFA_SOLICITAR_NUEVO_EMAIL = 'Solicitá uno nuevo';

// ---------------------------------------------------------------------------
// Identity Providers
// ---------------------------------------------------------------------------
export const ID_URUGUAY_PROVIDER = 'Usuario gub.uy';
export const CEDULA_ELECTRONICA_ID = 'ci_electronica';
export const CEDULA_ELECTRONICA_NOMBRE = 'Cédula de Identidad con Chip';
export const CEDULA_ELECTRONICA_DESCRIPTION =
  'Realiza todos los trámites con tu CI, lector y PIN';
export const CEDULA_ELECTRONICA_NIVEL = 'Avanzado';
export const IDP_ABITAB = 'abitab';
export const IDP_TUID_ANTEL = 'tuid_antel';
export const IDP_AE3_LIST = [IDP_ABITAB, IDP_TUID_ANTEL];
export const ERROR_WITH_IDENTITY_PROVIDER =
  'Ups! Estamos teniendo problemas con el servicio. Probá otro método de iniciar sesión.';

// ---------------------------------------------------------------------------
// Otros
// ---------------------------------------------------------------------------
export const RESEND_EMAIL_TEXT = '¿No lo recibiste aún?';
export const RESEND_EMAIL_LINK_TEXT = 'Solicitá uno nuevo »';
export const RESEND_EMAIL_LINK_ARIA_LABEL = elem => `Solicitá un nuevo ${elem}`;

export const REESTABLECE_TU_CONTRASENA =
  'Reestablece tu contraseña para desbloquearla.';

export const CONTACTO_TEXT = '¿Seguís teniendo problemas?';
export const CONTACTO_LINK_TEXT = 'Comunicate con nosotros »';
export const CONTACTO_LINK_ARIA_LABEL = 'Comunicate con nosotros';

export const FIRMA_ELECTRONICA_ASOCIAR_REQUERIMIENTO =
  'Para asociar tu firma electrónica necesitás estar en una computadora.';
export const FIRMA_ELECTRONICA_ASOCIAR_VALIDAR_CORREO =
  'Para poder asociar tu firma electrónica necesitás primero validar tu dirección de correo electrónico. Revisá el correo que te enviamos a tu casilla.';
export const FIRMA_ELECTRONICA_ASOCIAR_ACTIVAR_CUENTA =
  'Para poder asociar tu firma electrónica necesitás primero tener tu cuenta activa. Revisá el correo que te enviamos a tu casilla.';

export const WARNING_REGISTRADO_POR_IDP =
  'Aparentemente ya estas registrado porque anteriormente iniciaste sesión con un proveedor de identidad.';
export const LINK_SIGN_IN = 'Iniciar sesión »';
export const YA_CASI_TERMINAS = 'Ya casi terminás...';
export const REVISA_CORREO_ELECTRONICO =
  'Para continuar, revisá el correo electrónico que enviamos a tu casilla.';
export const RECIBIRAS_INSTRUCCIONES_SI_ESTAS_EN_SISTEMA =
  'Si los datos que ingresaste existen en nuestro sistema recibirás en tu casilla las instrucciones para continuar.';
export const CORREO_CREADO_EXITOSAMENTE =
  'Tu correo electrónico se modificó exitosamente';
export const REVISA_CORREO_Y_SEGURIDAD =
  'Dedicá unos instantes a revisar tu casilla de correo y la configuración de seguridad para asegurarte de que los cambios se hayan comunicado y actualizado correctamente.';

// ---------------------------------------------------------------------------
// Header
// ---------------------------------------------------------------------------
export const HEADER_LOGIN_TEXT = 'Ingresá';
export const HEADER_LOGIN_ARIA_LABEL = 'Iniciar sesión';
export const HEADER_REGISTRO_TEXT = 'Registrate';
export const HEADER_REGISTRO_ARIA_LABEL = 'Registrate';
export const HEADER_MENU_ALT_TEXT = 'Abrir menú';
export const HEADER_LOGOUT_TEXT = 'Cerrar sesión';
export const HEADER_LOGOUT_ARIA_LABEL = 'Cerrar sesión';
export const HEADER_SEGURIDAD_ARIA_LABEL = 'Seguridad';
export const HEADER_SEGURIDAD_SCREEN_TEXT = 'Seguridad';
export const HEADER_GUB_UY = 'gub.uy';
export const HEADER_ID_URUGUAY = 'ID Uruguay';

// ---------------------------------------------------------------------------
// Registro
// ---------------------------------------------------------------------------
export const REGISTRATE = 'Registrate en usuario gub.uy';
export const BENEFICIOS_REGISTRO =
  'Cuando crees tu usuario podrás ingresar de manera cómoda, fácil y segura a la mayoría de los servicios digitales del Estado uruguayo.';
export const FORTALEZA_PASSWORD = 'Fortaleza de tu contraseña';
export const PASSWORD_SEGURA = '¿Cómo es una contraseña segura?';
export const TIP_PASSWORD_SEGURA_1 =
  'Una contraseña segura es larga y difícil de adivinar. Crea una frase larga que sea entendible únicamente por ti.';
export const TIP_PASSWORD_SEGURA_2 =
  'Podés agregarle números y caracteres especiales si querés, pero tené en cuenta que sea fácil de recordar después!';
export const NUMERO_DOCUMENTO = 'numeroDocumento';
export const NUMERO_REGISTRADO =
  'El número de cédula/documento/pasaporte ya se encuentra registrado en usuario gub.uy.';
export const IDENTIFICATE = 'Identificate con usuario gub.uy';
export const COMPLETA_EXACTAMENTE =
  'Completa estos datos exactamente como están en tu documento de identidad.';
export const NO_RECIBISTE_CORREO =
  '¿No recibiste el correo electrónico de verificación de tu cuenta?';
export const EMAIL_AVISO =
  'Se recomienda ingresar un correo personal. Esto es importante para recuperar la contraseña en el futuro.';
export const PRIMER_NOMBRE = 'Primer nombre';
export const SEGUNDO_NOMBRE = 'Segundo nombre';
export const PRIMER_APELLIDO = 'Primer apellido';
export const SEGUNDO_APELLIDO = 'Segundo apellido';
export const PAIS_EMISOR = 'País emisor';
export const TIPO_DOCUMENTO = 'Tipo de documento';
export const NUMERO_DE_DOCUMENTO = 'Número';
export const CORREO = 'Correo electrónico';
export const REPETIR_CORREO = 'Repetir correo electrónico';
export const CONTRASENA = 'Crear contraseña';
export const REPETIR_CONTRASENA = 'Repetir contraseña';
export const TERMINOS_CONDICIONES =
  'Acepto los Términos y Condiciones y la Política de Privacidad de gub.uy.';
export const CREAR_USUARIO = 'Crear mi usuario';
export const ERROR_BACKEND_CIUDADANO_INHABILITADO_SERVICIO_EXTERNO =
  'No es posible validar tus datos contra el servicio de identificación';
export const ERROR_BACKEND_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO =
  'Verifica que tus datos personales coincidan con los de la cédula de identidad';
export const ERROR_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO =
  'Los datos son incorrectos. Asegúrate que el primer apellido y el número de documento coincidan con los de tu cédula de identidad.';
export const UPS_PROBLEMA = 'Ups, ocurrió un problema...';
export const LINK_INEXISTENTE_O_INVALIDO =
  'Al parecer el vínculo no existe o expiró y ya no es válido. Para continuar te recomendamos solicitar uno nuevo.';
export const SOLICITAR_VINCULO_NUEVO = 'Solicitar vínculo nuevo';
export const PREGUNTAS_FRECUENTES = 'Preguntas Frecuentes';
export const COMUNICATE_CON_NOSOTROS = 'comunicate con nosotros.';
export const CUENTA_FUE_ACTIVADA = 'Tu cuenta fue activada';
export const BIENVENIDA_GUBUY = '¡Bienvenido a tu Usuario gub.uy!';
export const EMPEZAR = 'Empezar';

// ---------------------------------------------------------------------------
// Niveles de seguridad
// ---------------------------------------------------------------------------

export const NIVEL_SEGURIDAD_BASICO = 'Básico';
export const NIVEL_SEGURIDAD_INTERMEDIO = 'Intermedio';
export const NIVEL_AUTOREGISTRO_CI = 'Autoregistro con CI';
export const NIVEL_AUTOREGISTRADO = 'Autoregistrado';
export const NIVEL_VERIFICADO = 'Verificado';
export const NIVEL_CERTIFICADO = 'Certificado';

// ---------------------------------------------------------------------------
// Tamaños
// ---------------------------------------------------------------------------
export const ANCHO_TABLET = 768;
export const ANCHO_ESCRITORIO = 1024;

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------

export const FLUJO_VIDEOLLAMADA_CONTINUAR = 'Continuar';
export const FLUJO_VIDEOLLAMADA_VOLVER_A_MI_PERFIL =
  'Volver a Administrar tu Usuario.gub.uy';
export const FLUJO_VIDEOLLAMADA_OBTENER_NIVEL_INTERMEDIO =
  'Obtener nivel de seguridad Intermedio';
export const FLUJO_VIDEOLLAMADA_DATOS_PERSONALES = 'Datos personales';
export const FLUJO_VIDEOLLAMADA_AGENDA = 'Agenda';
export const FLUJO_VIDEOLLAMADA_CONFIRMACION = 'Confirmación';
export const FLUJO_VIDEOLLAMADA_CONFIRMAR_AGENDA = 'Confirmar agenda';

export const IMAGEN_NUMERO_SERIE_CI =
  'Imagen con indicaciones de donde está el número de serie en el dorso de la cédula';
export const LARGO_NUMERO_SERIE_CI = 6;
export const YA_ELEVADO_TEXTO =
  'Usted ya realizó esta acción o no tiene permisos para hacerlo';
export const VIDEOLLAMADA_CONFIRMADA = 'La videollamada fue confirmada';
export const VIDEOLLAMADA_CONFIRMADA_NOTIFICACION =
  'En breve recibirás un mail con el link a la videollamada';
export const VIDEOLLAMADA_CANCELADA = 'La videollamada fue cancelada';
export const VIDEOLLAMADA_CANCELADA_DETALLE =
  'Puedes agendar otra en caso de que lo necesites';

// ---------------------------------------------------------------------------
// Estados
// ---------------------------------------------------------------------------

export const ESTADO_RECHAZADO_AGENTE = 'Rechazado agente';
export const ESTADO_EXITOSO = 'Exitoso';
export const ESTADO_AGENDADO = 'Agendado';
export const ESTADO_PENDIENTE_AGENTE = 'Pendiente agente';
export const ESTADO_PENDIENTE_USUARIO = 'Pendiente usuario';
export const ESTADO_PENDIENTE_VERIFICACION = 'Pendiente verificación';
export const ESTADO_RECHAZADO_VERIFICACION = 'Rechazado verificación';
export const ESTADO_ERROR = 'Error';
export const ESTADOS_INGRESAR_VIDEOLLAMADA = [
  ESTADO_AGENDADO,
  ESTADO_PENDIENTE_AGENTE,
  ESTADO_RECHAZADO_VERIFICACION,
  ESTADO_ERROR,
  ESTADO_PENDIENTE_VERIFICACION,
];

// ---------------------------------------------------------------------------
// REQUISITOS
// ---------------------------------------------------------------------------

// Condiciones de la Videollamada
export const PARRAFO_CONDICIONES =
  'Para realizar este trámite debe aceptar los siguientes términos y condiciones:';
export const CONDICIONES_MAYOR_DE_EDAD =
  'La persona usuaria deberá ser mayor de edad al momento de realizar la videollamada.';
export const CONDICION_CEDULA_VIGENTE =
  'Contar con cédula de identidad vigente y en buenas condiciones.';
export const CONDICION_CEDULA_CON_CHIP =
  'La persona usuaria deberá contar con la cédula de identidad con chip y conocer su número de serie que debe estar legible.';
export const CONDICION_BUENA_CONEXION =
  'Buena conexión a internet, que permita hacer en forma correcta videollamadas.';
export const CONDICION_BUENA_LUZ =
  'Ubicarse en un lugar con buena luz, fondo neutro (ej. pared blanca), adecuado para escuchar y hablar fácilmente. ';
export const CONDICION_BUENA_CALIDAD =
  'Comprobar que la calidad de imagen de la cámara sea clara y nítida: posicionarse a una distancia de 50 cm (aproximadamente la distancia hasta el codo al extender el brazo hacia adelante).';
export const CONDICION_EVITAR_ACCESORIOS =
  'No utilizar accesorios como gorros, tapabocas, caravanas o lentes de sol. En caso de utilizar lentes de aumento es posible que la persona que colabora con la función pública solicite que se los quite en algún momento durante la videollamada.';
export const CONDICION_EVITAR_MAQUILLAJE =
  'Evitar usar maquillaje y tener sombras sobre el rostro.';
export const CONDICION_FOTO_ROSTRO =
  'Durante la videollamada la persona que colabora con la función pública tomará una foto del rostro de la persona usuaria y un sistema informático con tecnología biométrica facial la comparará con la foto de la cédula de identidad de la Dirección Nacional de Identificación Civil. Esta será una de las evidencias para validar la identificación de la persona usuaria. En el sistema solamente almacenará el vector obtenido de cada foto (la foto de la cédula y la foto tomada en la videollamada) y el porcentaje de acierto entre ambas imágenes. No se almacenarán las imágenes.';
export const CONDICIONES_TITULO = 'Condiciones para la videollamada';
export const ACEPTO_TERMINOS = 'Acepto los términos y condiciones';

export const CONDICIONES_PARRAFO_INICIAL =
  'El objetivo de este trámite es que, mediante una videollamada, donde estén las condiciones y garantías dadas para ambas partes, un funcionario pueda verificar la identidad del usuario y el registro del usuario en usuario.gub.uy pase de nivel básico a intermedio. El nivel intermedio habilitará la posibilidad de realizar la gran mayoría de trámites en forma 100% digital.';
export const CONDICIONES_TEXTO_NEGRITA =
  'Este trámite solamente puede ser realizado por el titular y tiene que ser mayor de edad. Vas a necesitar cédula con chip, conexión de banda ancha de internet, cámara y micrófono.';

export const PARRAFO_ETAPAS =
  'Este trámite posee tres etapas que pueden ser realizadas 100% en línea:';
export const ETAPA_AGENDA_VIDEOLLAMADA =
  'La persona usuaria agenda la videollamada (etapa que está iniciando actualmente).';
export const ETAPA_REALIZA_VIDEOLLAMADA =
  'Se realiza la videollamada, donde un funcionario valida la identificación de la persona usuaria. ';
export const ETAPA_ACEPTA_TERMINOS =
  'La persona usuaria ingresa a usuario.gub.uy, acepta los términos y su registro se eleva a nivel intermedio.';

// CLÁUSULA DE TRATAMIENTO DE DATOS PERSONALES
export const CLAUSULA_TITULO = 'Cláusula de tratamiento de datos personales';
export const CLAUSULA_PRIMER_PARRAFO =
  'De conformidad con la Ley Nº 18.331, de 11 de agosto de 2008, de Protección de Datos Personales y Acción de Habeas Data (LPDP), los datos personales suministrados serán incorporados y tratados en la Base de Datos Usuario.gub.uy cuya finalidad es identificarse para realizar diversos trámites en línea.';
export const CLAUSULA_SEGUNDO_PARRAFO =
  'En el tratamiento de los datos personales se garantizará un nivel de protección adecuado y se guardará estricto secreto profesional sobre éstos, de acuerdo con lo dispuesto por el artículo 302 del Código Penal.';
export const CLAUSULA_TERCER_PARRAFO =
  'Se tomarán, asimismo, las medidas de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado por parte de terceros que los puedan utilizar para finalidades distintas o incompatibles para las que han sido solicitados al titular. No se autoriza ninguna comunicación de los datos de la Base de Datos, salvo las excepciones establecidas en la LPDP.';
export const CLAUSULA_CUARTO_PARRAFO =
  'El Organismo responsable de la Base de Datos es AGESIC y la dirección donde el titular podrá ejercer los derechos de acceso, rectificación, actualización, inclusión o supresión es Liniers 1324, Piso 4° Torre Ejecutiva Sur.';
export const CLAUSULA_ACEPTA = 'Acepto la cláusula de datos';

// ---------------------------------------------------------------------------
// PASO 1 ERRORES Y TEXTOS
// ---------------------------------------------------------------------------

export const TEXTO_ERROR_NUMERO_SERIE =
  'El número de serie no coincide con los datos registrados. Por favor, inténtalo de nuevo.';

export const TEXTO_ERROR_PRIMER_APELLIDO =
  'Tu primer apellido no coincide con el registrado con este número de serie. No puedes continuar con este trámite.';

export const TEXTO_ERROR_MAYOR_DE_EDAD =
  'Necesitas ser mayor de edad para poder realizar este trámite.';

export const TEXTO_ERROR_PEDIDO_FALLIDO =
  'No fue posible verificar sus datos. Intentelo de nuevo.';

export const TERMINOS_CONDICIONES_GUARDAR_NUMERO_SERIE =
  'Deseo que el sistema recuerde el número de serie de mi cédula de identidad con chip. En caso contrario, se solicitará esta información nuevamente durante la videollamada.';

export const TEXTO_PRESIONE_CONTINUAR =
  'Presionar “Continuar” para verificar la identidad con la Dirección Nacional de Identificación Civil.';

export const TEXTO_CASO_ERROR =
  'En caso de falla, sugerimos que revise el número de serie dado que se pueden confundir el número cinco (5) con la letra “S” o ceros (0) con la letra “O”.';

export const TEXTO_COMPLETAR_DATOS =
  'Completa estos datos exactamente como están en tu documento de identidad.';

export const TEXTO_NUMERO_SERIE_DE_CEDULA =
  'Número de serie de tu cédula con chip';

export const TEXTO_NUMERO_SERIE = 'Número de serie';

export const TEXTO_PLACEHOLDER_NUMERO_SERIE = 'Ej. 007HEC';

export const TEXTO_NOMBRE_COMPLETO = 'Nombre completo';

export const TEXTO_NUMERO_TELEFONO = 'Número de teléfono';

export const TEXTO_NUMERO_TELEFONO_AVISO =
  'Ingresa tu número sin el 0 inicial luego de los dígitos del código país.';

export const TEXTO_OPCIONAL = ' (Opcional)';

export const TEXTO_PLACEHOLDER_NUMERO_TELEFONO = 'Ej. +598 999 999 99';

// ---------------------------------------------------------------------------
// PASO 2
// ---------------------------------------------------------------------------

export const ELIGE_DIA_HORA = 'Elegir día y hora';

export const ELIGE_DIA = 'Elegir día';

export const ELIGE_HORA = 'Elegir hora';

export const NO_HAY_CUPOS_DISPONIBLES = 'No hay cupos disponibles';

export const DURACION_ESTIMADA_VIDEOLLAMADA = `La duración estimada de la videollamada es de ${window.REACT_APP_DURACION_VIDEOLLAMADA} minutos.`;

export const ACLARACION_HORA_URUGUAY =
  'Todos los horarios en este sitio se encuentran en la hora oficial de Uruguay.';

export const INSTRUCCIONES_INICIO_SESION_INICIO = 'En ese momento, ';

export const INSTRUCCIONES_INICIO_SESION_FIN =
  ' (a la videollamada). Un agente comenzará la videollamada a la hora programada.';

export const INSTRUCCIONES_INICIO_SESION_IMPORTANTE =
  'deberá iniciar sesión en Usuario gub.uy y una vez dentro clickear el botón INGRESAR';
// ---------------------------------------------------------------------------
// PASO 3 ERRORES Y TEXTOS
// ---------------------------------------------------------------------------

export const CONFIRMA_DATOS = 'Confirmar los datos para agendar';
export const AGENDA_VIDEOLLAMADA = 'Agenda de la videollamada';
export const FECHA_LABEL = 'Fecha:';
export const HORA_LABEL = 'Hora:';
export const NOMBRE_LABEL = 'Nombre:';
export const APELLIDO_LABEL = 'Apellido:';
export const TIPO_DOCUMENTO_LABEL = 'Tipo de documento:';
export const NUMERO_DOCUMENTO_LABEL = 'Número de documento:';
export const PAIS_EMISOR_LABEL = 'País emisor:';
export const CORREO_LABEL = 'Correo electrónico:';
export const HORA_URUGUAY = '(Hora de Uruguay)';
export const ALGUN_DATO_INCORRECTO =
  '*Si algún dato no es correcto, un agente podrá corregirlo durante la videollamada';
export const ERROR_CUPOS =
  'Los cupos de este horario han sido ocupados recientemente, por favor vuelva al paso 2 para solicitar agenda en otro horario';
export const ERROR_VIDEOLLAMADA_AGENDADA =
  'Su usuario ya tiene una videollamada agendada, puede ver sus datos volviendo a Administrar tu Usuario.gub.uy';
export const ERROR_VIDEOLLAMADA_CONSULTA_AGENDADA_PARA_EMAIL =
  'Ya hay una consulta agendada para el mail ingresado. Revise su bandeja de entrada por el mail de confirmación';
export const ERROR_VIDEOLLAMADA_CONSULTA_AGENDADA_PARA_DOCUMENTO =
  'Ya hay una consulta agendada para el documento ingresado. Revise su bandeja de entrada por el mail de confirmación';
// ---------------------------------------------------------------------------
// EXITO VIDEOLLAMADA
// ---------------------------------------------------------------------------

export const EXITO_VIDEOLLAMADA = 'La videollamada quedó confirmada para el ';
export const DURACION_ESTIMADA = `Recordamos que la misma puede tener una duración de ${window.REACT_APP_DURACION_VIDEOLLAMADA} minutos.`;
export const getTextoConfirmadaPara = (date, hora) =>
  `${dayjs(date).locale('es').format('D [d]e MMMM [d]e YYYY ')}${dayjs(hora)
    .locale('es')
    .format('[a] l[as] HH:mm [hs]')}`;
export const EXITO_NECESITAS_MODIFICAR =
  'Para modificar la reserva, será necesario cancelar la agenda actual y realizarla nuevamente.';
export const TIEMPO_Y_FORMA_CONFIRMACION =
  'Tenes 2 horas para confirmar la reserva, de lo contrario perderás el turno. Esto se hace a través del mail que ingresaste.';
export const IR_A_MI_PERFIL = 'Administrar mi Usuario.gub.uy';
export const NO_TIENES_VIDEOLLAMADA = 'Ya no tienes videollamada agendada.';
export const AGREGAR_CALENDARIO =
  'Es posible agregar la videollamada como un evento en el calendario personal:';
export const TITULO_EVENTO_CALENDARIO = 'Videollamada Gub.uy';
export const DESCRIPCION_EVENTO =
  'Recuerda que debes contar con buena conexión a Internet, cámara y micrófono. La videollamada solamente podrá ser realizada por el titular y debe contar con la cédula de identidad vigente. Para ingresar a la misma debes entrar a Administrar tu Usuario.gub.uy: https://mi.iduruguay.gub.uy/login';

// ---------------------------------------------------------------------------
// INFORMACION NIVEL DE SEGURIDAD
// ---------------------------------------------------------------------------

export const textoTieneVideollamada = (fecha, horaInicio) =>
  `${fecha} a las ${horaInicio} hs`;

export const TEXTO_BOTON_CANCELAR_AGENDA = 'Cancelar';

export const TEXTO_BOTON_INGRESAR_VIDEOLLAMADA = 'Ingresar';

// ---------------------------------------------------------------------------
// CANCELAR TRAMITE VIDEOLLAMADA
// ---------------------------------------------------------------------------

export const REVISA_DATOS = 'Revisa los datos para cancelar';
export const CANCELAR_AGENDA = 'Cancelar agenda';

// ---------------------------------------------------------------------------
// ELEVARSE POR VIDEOLLAMADA
// ---------------------------------------------------------------------------
export const BOTON_ACEPTAR = 'Aceptar';
export const EXITO_ELEVAR_TITULO = 'Solicitud aceptada';
export const EXITO_ELEVAR_TEXTO =
  '¡Has obtenido un nivel de seguridad intermedio!';
export const BOTON_FINALIZAR = 'Finalizar';
export const VALIDAR_TEXTO = ` validar `;
export const TITULO_REQUISITOS =
  'Términos para validar la Identificación Digital';
export const descripcionRequisitos = (fecha, hora) =>
  `Usted realizó la videollamada el día ${fecha} a la hora ${hora} ${HORA_URUGUAY} donde una persona que colabora con la función pública pudo comprobar su identidad.`;
export const PARA_ACEPTAR_TEXTO =
  'Para elevar su usuario deberá aceptar los siguientes términos:';
export const PRIMERO_TEXTO = 'Primero: ';
export const SEGUNDO_TEXTO = 'Segundo: ';
export const TERCERO_TEXTO = 'Tercero: ';
export const CUARTO_TEXTO = 'Cuarto: ';
export const PRIMERA_CONDICION_TEXTO =
  'Por el presente se solicita la habilitación de usuario para relacionarse electrónicamente con el Estado uruguayo.';
export const SEGUNDA_CONDICION_TEXTO =
  'El usuario se obliga a hacer un uso adecuado del código de usuario y contraseña ' +
  'provistas. El uso inadecuado de cualquiera de dichos elementos habilitará a la Agencia ' +
  'para el Desarrollo del Gobierno de Gestión Electrónica y la Sociedad de la Información y ' +
  'del Conocimiento (AGESIC) a revocar la habilitación en el Sistema.';
export const TERCERA_CONDICION_TEXTO =
  'El usuario acepta expresamente la recepción por correo electrónico -declarado ' +
  'como primario- de las instrucciones necesarias para utilizar el Sistema y las demás ' +
  'aplicaciones a las que se adhiera, a partir de la habilitación correspondiente.';
export const CUARTA_CONDICION_TEXTO =
  'En cumplimiento de las normas legales y reglamentarias en materia de protección ' +
  'de datos se informa que los datos personales consignados en el presente formulario serán ' +
  'incorporados y tratados en la Base de Datos del Sistema de Usuarios Únicos de la ' +
  'Plataforma de Gobierno Electrónico, cuya finalidad es contar con la información necesaria ' +
  'para utilizar aplicaciones puestas a disposición por las Entidades Públicas. El responsable ' +
  'de la Base de Datos es la AGESIC, y la dirección donde se podrán ejercer los derechos de ' +
  'acceso, rectificación, actualización, inclusión o supresión es Liniers 1324, piso 4º, Torre ' +
  'Ejecutiva Sur, de la ciudad de Montevideo, Uruguay.';
export const MENSAJE_FINAL_TEXTO =
  'Para poder acceder a aquellas aplicaciones del Estado que requieran acreditar tu ' +
  'identidad es necesario que acepte estos términos.';
export const REQUISITOS_TEXTO = 'He leído las condiciones y estoy de acuerdo.';
export const ADICIONAL_TEXTO =
  'Adicionalmente le sugerimos que configure un segundo factor de autenticación (o ' +
  'verificación en dos pasos) para mayor seguridad. Esto se puede realizar accediendo a ' +
  '“Administra tu Usuario gub.uy” en la opción “Verificación en dos pasos”.';
export const ERROR_TEXTO =
  'No se ha podido concretar la solicitud. Inténtelo más tarde.';

// ---------------------------------------------------------------------------
// PAGINAS ESTATICAS
// ---------------------------------------------------------------------------

export const MAS_INFORMACION_METODOS_DISPONIBLES =
  'Más información sobre métodos disponibles';
export const VOLVER_ATRAS = 'Volver atrás';

// ---------------------------------------------------------------------------
// FOOTER
// ---------------------------------------------------------------------------
export const USUARIO_GUB_UY = 'Usuario gub.uy';
export const SITIO_OFICIAL_TEXTO =
  'Sitio oficial de la República Oriental del Uruguay';

// ---------------------------------------------------------------------------
// ESTADOS REDUCER MIS DATOS
// ---------------------------------------------------------------------------
export const MIS_DATOS_ESTADO_PENDIENTE = 'Pendiente';
export const MIS_DATOS_ESTADO_EXITO = 'Exito';
export const MIS_DATOS_ESTADO_ERROR = 'Error';

// ---------------------------------------------------------------------------
// CONTACTO
// ---------------------------------------------------------------------------
export const CONTACTO_TITULO_TEXTO = 'Contacto';
export const CONTACTO_SUBTITULO_TEXTO =
  'Contactate con nosotros a través de este formulario. Recibirás una copia del mensaje en tu casilla de correo electrónico.';

// ---------------------------------------------------------------------------
// CONTACTO VIDEOLLAMADA
// ---------------------------------------------------------------------------
export const CONTACTO_VIDEOLLAMADA_TITULO_TEXTO = 'Agenda Soporte';
export const CONTACTO_VIDEOLLAMADA_SUBTITULO_TEXTO =
  'Agendá tu turno de videollamada para resolver tus consultas. Recibirás una copia de la confirmación a tu casilla de correo electrónico.';
export const VOLVER_AL_INICIO = 'Volver al inicio';

// ---------------------------------------------------------------------------
// LOGOUT
// ---------------------------------------------------------------------------
export const LOGOUT_CERRANDO_SESION = 'Cerrando sesión';
export const REDIRIGIENDO = 'Redirigiendo...';

// ---------------------------------------------------------------------------
// SNACKBAR
// ---------------------------------------------------------------------------
export const SNACKBAR_DURACION = 6000;
export const SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION =
  'El email de activación fue enviado nuevamente';
export const SNACKBAR_TEXTO_REENVIO_EMAIL_ACTIVACION_ERROR =
  'Ocurrió un error al enviar el email de activación';
export const SNACKBAR_TEXTO_REENVIO_EMAIL_VERIFICACION =
  'El email de verificación fue enviado nuevamente';
export const SNACKBAR_TEXTO_CAMBIO_EMAIL =
  'Las instrucciones fueron enviadas nuevamente';
export const SNACKBAR_TEXTO_LOGIN =
  'El código de verificación fue enviado nuevamente';
export const DEFAULT_SNACKBAR_INFO = { mostrar: false, texto: '' };

// ---------------------------------------------------------------------------
// Modificar número de teléfono
// ---------------------------------------------------------------------------
export const MODIFICAR_NUMERO_TELEFONO_TITULO =
  'Modificá tu número de teléfono móvil';
export const MODIFICAR_NUMERO_TELEFONO_DESCRIPCION =
  'Ingresá tu nuevo número de teléfono móvil.';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO = 'Continuar';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL =
  'Confirmar nuevo número de teléfono';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO = 'Cancelar';
export const MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL =
  'Cancelar cambio de número de teléfono';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR =
  'No ha sido posible modificar el número de teléfono.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR_VACIO =
  'No ha sido posible modificar el número de teléfono.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO =
  'Tu número de teléfono ha sido modificado.';
export const MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO =
  'Dedicá unos instantes a revisar tu número en autogestión para asegurarte de que los cambios se hayan concretado.';
export const MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER = 'Volver a autogestión.';

// ---------------------------------------------------------------------------
// Verificar contraseña
// ---------------------------------------------------------------------------
export const VALIDAR_CONTRASENA_TEXTO_TITULO = 'Verificá tu identidad';
export const VALIDAR_CONTRASENA_TEXTO_DESCRIPCION =
  'Ingresá tu contraseña actual para continuar.';
export const VALIDAR_CONTRASENA_INPUT_LABEL = 'Contraseña';
export const VALIDAR_CONTRASENA_INPUT_LINK_TEXTO = '¿Olvidaste tu contraseña?';

// ---------------------------------------------------------------------------
// SAML INDEX
// ---------------------------------------------------------------------------
export const SAML_INDEX_TITULO = 'Service Provider';
export const SAML_INDEX_TEXTO_BOTON_LOGIN = 'AUTHENTICATION Request gub.uy';
export const SAML_INDEX_TEXTO_BOTON_LOGOUT = 'LOGOUT Request gub.uy';
export const SAML_INDEX_TEXTO_FALTA_LOGIN = 'Falta iniciar sesión';

// ---------------------------------------------------------------------------
// REESTABLECER CONTRASENA
// ---------------------------------------------------------------------------
export const MODIFICA_TU_CONTRASENA = 'Modificá tu contraseña';
export const CREA_UNA_NUEVA_CONTRASENA =
  'Creá una nueva contraseña para iniciar sesión.';
export const NUEVA_CONTRASENA = 'Nueva contraseña';
export const REPETIR_NUEVA_CONTRASENA = 'Repetir nueva contraseña';
export const CONTINUAR = 'Continuar';

// ---------------------------------------------------------------------------
// ERRORES BROKER
// ---------------------------------------------------------------------------
export const ERROR_BROKER_RECOVERY_CODE_TIEMPO_EXPIRADO =
  'El tiempo de inicio de sesión expiró. Comienza de nuevo.';

// ---------------------------------------------------------------------------
// Tipos de videollamada
// ---------------------------------------------------------------------------
export const TIPO_TURNO_CONSULTA = 'Consulta';

// ---------------------------------------------------------------------------
// ERRORES VIDEOLLAMADA
// ---------------------------------------------------------------------------
export const CONSULTA_PENDIENTE_PARA_EMAIL_ERROR = email =>
  `Ya hay una videollamada de consulta pendiente asociada al e-mail ${email}`;
export const CONSULTA_PENDIENTE_PARA_DOCUMENTO_ERROR = doc =>
  `Ya hay una videollamada de consulta pendiente asociada al documento ${doc}`;
export const CUPO_OCUPADO_ERROR = (fecha, hora) =>
  `No hay disponibilidad para ${fecha} a las ${hora}`;
